import(/* webpackMode: "eager" */ "/app/node_modules/@livekit/components-styles/dist/general/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@livekit/components-styles/dist/general/prefabs/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/sessionWrapper/sessionWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/app/src/components/sidebar/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "/app/src/hooks/useConfig.tsx");
;
import(/* webpackMode: "eager" */ "/app/styles/globals.css");
