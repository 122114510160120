'use client';

import React, { Suspense, useState } from 'react';
import styles from '../../styles/Home.module.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlaygroundHeader } from "../components/playground/PlaygroundHeader";
import { useSession } from 'next-auth/react';
import { Footer } from '@/src/components/footer/footer';
import { useConfig } from "@/src/hooks/useConfig";

export default function Page() {
  const {data:session} = useSession();
  const {config} = useConfig();
  const headerHeight = 60;
  return (
    <>
      <PlaygroundHeader title={config.title} height={60} accentColor={config.settings.accent_color} />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <main
        className={styles.main}
        data-lk-theme="default"
        style={{
          // backgroundImage: `url(${"/hero_image_w_blur.png"})`, 
          // backgroundSize: 'cover',
          minHeight: '60vh', // Height of the main section
          width: '100%', // Full width
          margin: '0 auto', // Center horizontally
          position: 'relative', // Ensure the logo is positioned relative to this container
        }}
      >
        {/* Logo as a foreground image */}
        {/* <img
          src="/logo-face.png"
          alt="Logo"
          style={{
            position: 'absolute', // Position it on top of the background
            top: '54%', // Adjust position vertically
            right: '20%', // Adjust position horizontally
            transform: 'translateY(-50%)', // Center the logo vertically
            maxWidth: '500px', // Maximum width of the logo
            maxHeight: '500px', // Maximum height of the logo
            zIndex: 10, // Ensure the logo is on top of the background
          }}
        /> */}

        <section style={{
          fontSize: "42px",
          lineHeight: "54px",
          fontFamily: '"Fira Sans", sans-serif',
          fontWeight: 600,
          // color: 'rgb(249, 250, 255)',
          overflowWrap: "break-word",
          minWidth: '50rem',
          width:"100%"
        }} className='text-gray-500'>
          <h2
            id="appTitle"
            className="mt-32 mb-16 reveal-from-bottom"
            data-reveal-delay="150"
            style={{
              fontSize: "42px",
              lineHeight: "54px",
              fontFamily: '"Fira Sans", sans-serif',
              fontWeight: 600,
              // color: 'rgb(249, 250, 255)',
              overflowWrap: "break-word",
            }}
          >
            TensorAgent <br />
            <p style={{
              fontSize: "42px",
              lineHeight: "54px",
              fontFamily: '"Fira Sans", sans-serif',
              fontWeight: 100,
              // color: 'rgb(249, 250, 255)',
              overflowWrap: "break-word",
            }}>Scale interviews. <br />Simple, Secure, Fast.</p>
          </h2>
          <p
            style={{
              fontSize: "24px",
              lineHeight: "54px",
              fontWeight: 400,
            }}
          >
            Give superpowers to your recruiters.
          </p>
          <p
            style={{
              fontSize: "24px",
              lineHeight: "54px",
              fontWeight: 400,
            }}
          >
            {session ? (
              <>
                {"Welcome "+session?.user?.name+"!"}
                <br/>
                {"Navigate to interviews to get started."}
              </>
            ): "Just sign in to get started."}
          </p>
        </section>

        <section>
          <div className="container">
            <div className="hero-inner section-inner">
              <div className="split-wrap invert-mobile">
                <div className="split-item">
                  <div className="hero-content split-item-content center-content-mobile">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>

      <Footer />
    </>
  );
}

