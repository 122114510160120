import { ReactNode, useMemo, useEffect, useState } from "react";
import Link from 'next/link'; 
import { useSession, signIn, signOut } from "next-auth/react"
import { Button } from "../button/Button";
import { useRouter, redirect } from 'next/navigation';
import { default as NextImage } from 'next/image';
import Logo from '../logo/logo';
import { useConfig } from "@/src/hooks/useConfig";
type PlaygroundHeader = {
  title?: ReactNode;
  height: number;
  accentColor: string;
  // isDarkMode: boolean;
  // setIsDarkMode: (isDarkMode: boolean) => void;
};

export const PlaygroundHeader = ({
  title,
  height,
  accentColor, // Receive backgroundColor
  // isDarkMode,
  // setIsDarkMode,
}: PlaygroundHeader) => {
  const router = useRouter()
  const {config, setUserSettings} = useConfig();
  const { data: session } = useSession()
  const onClinkSignOut = async () => {
    await signOut()
  }
  useEffect(() => { // Use useEffect for redirect
    if (!session || !session.user) {
      const params = new URLSearchParams(window.location.search);  // Get URL parameters
      const pathname = window.location.pathname; // Get the current path
      const isPrivacyPage = pathname.includes('privacy') || pathname.includes('terms-of-service')|| pathname.includes('signin')
      if (!params.get('recorder') && !isPrivacyPage) {  // Check if recorder is NOT true
        router.push('/');
      }
    }
  }, [session, router]); // Add router as a dependency
  const [isDarkMode, setIsDarkMode] = useState(false); // Default to dark mode

  // useEffect(() => {
  //     const storedTheme = localStorage.getItem('theme');
  //     if (storedTheme) {
  //       setIsDarkMode(storedTheme === 'dark');
  //     }
  // }, []);

  const toggleTheme = () => {
      const newTheme = isDarkMode ? 'light' : 'dark';
      setIsDarkMode(!isDarkMode);
      localStorage.setItem('theme', newTheme);
  };
  const authContent = () => {
    if (session) {
      return (
        <>
        <div className="flex items-center gap-3 justify-end px-5"> 
            <Button accentColor={accentColor} onClick={onClinkSignOut} style={{width:80,height:30,fontSize:'small'}}>Sign out</Button>
        </div>
        
        </>

      )
    }
    return (
      <div className="flex items-center gap-3 justify-end px-5"> 
           <Button accentColor={accentColor} onClick={() => {
            router.push('/auth/signin');
           }} style={{width:80,height:30,fontSize:'small'}}>Sign in</Button> 
      </div>
    )
  }

  return (
    <div
      // className={`w-full flex gap-4 justify-between items-center shrink-0 border-b rounded-sm border-gray-800 text-${accentColor}-500`}
      className={`flex gap-4 justify-between items-center shrink-0 border-b rounded-sm w-full
        ${isDarkMode ? 'bg-gray-800 border-gray-800 text-gray-100' : 'bg-[rgb(250,230,208)] border-[rgb(247,245,242)] text-[rgb(32,33,36)]'}
      `}
      style={{
        height: (height) + "px",
        // backgroundColor: '#000000'
      }}
    >
      <div className="flex items-center gap-3 w-full">
        <div className="flex">
          {/* <a href="https://www.pashyatu.ai">{<img src="/vidvatta_illustration.png" alt="Vidvatta Logo" width="60" height="60" />}</a> */}
          {/* <a href="https://www.pashyatu.ai">{<img src="/logo_pashyatu.png" alt="PashyatuHire Logo" width="75" height="60" className="mx-5 mt-2" />}</a> */}
          <Link href="/">
              <Logo className="mx-5" accentColor={accentColor}/>
          </Link>
        </div>
        <div className="w-full text-center">
            <h1 className={`text-${accentColor}-500 text-xl font-semibold`}>{title}</h1>
          </div>
      {authContent()}

      </div>
      {/* <button onClick={() => {
                const userSettings = { ...config.settings };
                userSettings.theme = userSettings.theme === 'light' ? 'dark' : 'light';
                setUserSettings(userSettings);
                setIsDarkMode(!isDarkMode);

              }}>
            {isDarkMode ? 'Light' : 'Dark '}
        </button> */}

    </div>
  );
};


