'use client';

import { SessionProvider } from 'next-auth/react';
export default SessionProvider;

// const SessionWrapper = ({children}: { children: React.ReactNode })=>{
//     return (<SessionProvider session={}>
//         {children}
//     </SessionProvider>)
// }
// export default SessionWrapper;