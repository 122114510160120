import React, { ButtonHTMLAttributes, ReactNode } from "react";

type ButtonProps = {
  accentColor: string;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = ({
  accentColor,
  children,
  className,
  disabled,
  ...allProps
}) => {
  return (
    <button
      className={`flex flex-row items-center justify-center ${
        disabled ? "pointer-events-none" : ""
      } text-gray-100 text-sm justify-center border border-transparent bg-${accentColor}-500 p-0 px-3 py-1 rounded-md transition ease-out duration-250 hover:bg-${accentColor}-700 hover:shadow-${accentColor} hover:border-${accentColor}-500 hover:text-${accentColor}-100 active:scale-[0.98] ${className}`}
      {...allProps}
    >
      {children}
    </button>
  );
};
