'use client';

import { useState,useEffect } from 'react';
import Link from 'next/link';
import {
  Home,
  Settings,
  Users,
  BarChart3,
  Files,
  Menu,
} from 'lucide-react';
import { useSession, signIn, signOut } from "next-auth/react"
import { Button } from "../button/Button";
import { useRouter, redirect } from 'next/navigation';

const menuItems = [
  { icon: Home, label: 'Dashboard', href: '/' },
  { icon: Users, label: 'Interview Templates', href: '/interview-templates' },
  { icon: BarChart3, label: 'Curation', href: '/curation' },
  { icon: BarChart3, label: 'Scheduler', href: '/scheduler' },
  { icon: Files, label: 'Interview', href: '/interview' },
  { icon: Files, label: 'Feedback', href: '/get-feedback' },
  { icon: Users, label: 'Candidate Tracker', href: '/candidate-tracker' },
  { icon: Settings, label: 'Settings', href: '/settings' },
];

export function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const router = useRouter()
  const { data: session } = useSession()
  useEffect(() => { // Use useEffect for redirect
      if (!session || !session.user) {
        const params = new URLSearchParams(window.location.search);  // Get URL parameters
        const pathname = window.location.pathname; // Get the current path
        const isPrivacyPage = pathname.includes('privacy') || pathname.includes('terms-of-service')|| pathname.includes('signin')
        if (!params.get('recorder') && !isPrivacyPage) {  // Check if recorder is NOT true
          router.push('/');
        }
      }
    }, [session, router]); // Add router as a dependency

    const authContent = () => {
        if (session) {
          return (
            <>
              {/* Invisible trigger area */}
              <div
                className="fixed left-0 top-0 z-30 h-screen w-2 cursor-pointer"
                onMouseEnter={() => setIsExpanded(true)}
              />
              
              {/* Sidebar */}
              <div
                className={`fixed left-0 top-0 z-40 h-screen bg-[rgb(255,255,255)] backdrop-blur supports-[backdrop-filter]:bg-[rgb(255,255,255)] 
                  border-r border-border/50 shadow-lg transition-all duration-300 ease-in-out w-64 
                  ${isExpanded ? 'translate-x-0' : '-translate-x-full'}`}
                onMouseLeave={() => setIsExpanded(false)}
              >
                <div className="flex h-16 items-center justify-between px-4 border-b border-border/50">
                  <h1 className="text-xl font-semibold text-primary text-gray-500">Navigation</h1>
                  <div
                    className="p-2 hover:bg-white/5 rounded-md cursor-pointer text-muted-foreground hover:text-primary transition-colors text-gray-500"
                    onClick={() => setIsExpanded(false)}
                  >
                    <Menu className="h-5 w-5" />
                  </div>
                </div>
                <div className="flex flex-col gap-1 p-3">
                  {menuItems.map((item) => {
                    const Icon = item.icon;
                    return (
                      <Link 
                        key={item.href} 
                        href={item.href}
                        className="flex items-center gap-2 px-3 py-2 text-muted-foreground hover:text-primary rounded-md hover:bg-black/5 transition-all duration-200 group relative overflow-hidden text-gray-500"
                      >
                        <div className="absolute inset-0 bg-primary/10 translate-x-[-100%] group-hover:translate-x-0 transition-transform duration-200" />
                        <Icon className="h-5 w-5 relative z-10 group-hover:text-primary transition-colors duration-200" />
                        <span className="relative z-10">{item.label}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </>
          );
        }
        return (<></>
        //   <div className="flex items-center gap-3 justify-end basis-3/7 px-5"> {/* Navigation links */}
        //        <Button accentColor='rose' onClick={() => {
        //         router.push('/auth/signin');
        //        }} style={{width:80,height:30,fontSize:'small'}}>Sign in</Button> 
        //   </div>
        )
      }

  return (<>{authContent()}</>)
}